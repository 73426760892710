import PropTypes from 'prop-types';

// Utils
import { cn } from 'utils';

// Styles
import styles from './TitleAccordion.module.css';

// Hooks
import useAccordion from '../../../src/hooks/useAccordion';

// Components
import Title from '../../atoms/Title/Title';
import Accordion from '../../atoms/Accordion/Accordion';

function TitleAccordion({
  title, subtitle, list, theme, className,
}) {
  const { indexExpanded, handleExpanded } = useAccordion({ defaultExpanded: 0 });

  return (
    <div className={cn([styles.wrapper, styles[theme], className])}>
      <div className={styles.container}>
        <Title
          title={title}
          subtitle={subtitle}
          theme={theme}
          className={styles.title}
        />
        <div className={styles.questions}>
          {list?.length ? list.map(({ data: item }, index) => (
            <Accordion
              key={`${item?.title}${index}`}
              item={item}
              isExpanded={indexExpanded === index}
              handleExpanded={() => handleExpanded(index)}
              theme={theme}
            />
          )) : null}
        </div>
      </div>
    </div>
  );
}

TitleAccordion.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  })),
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  className: PropTypes.string,
};

TitleAccordion.defaultProps = {
  title: '',
  subtitle: '',
  list: [],
  theme: 'light',
  className: '',
};

export default TitleAccordion;
